<template>
  <div>Logging you out...</div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  created () {
    Auth.signOut();
  }
};
</script>
